<template>
	<div>
		<div>
			<div>
				<VPopup :closePopup="onClosePopup" style="width: 100%">
					<template #header>
						<div>
							{{ formName }}
						</div>
					</template>
					<template #body>
						<div style="margin-top: 30px">
							<b-table :data="$props.data" :columns="$props.columns"></b-table>
						</div>
					</template>
					<template #footer>
						<div>
							{{ formName }}
						</div>
					</template>
				</VPopup>
			</div>
		</div>
	</div>
</template>

<script>
import { ref } from '@vue/composition-api'
import VPopup from '@/_srcv2/pages/admin/edit-stock-prices/sub-components/VPopup.vue'
import useCreateCustomerVisitState from '@/_srcv2/pages/business-development/customer-visits/scripts/useCreateCustomerVisitState.js'

export default {
	name: 'PreviewVisitList',
	components: {
		VPopup,
	},
	props: {
		data: {
			type: Array,
			default: () => [],
		},
		columns: {
			type: Array,
			default: () => [],
		},
	},
	setup() {
		const formName = ref('Preview List')
		const { isPreviewVisitListVisible } = useCreateCustomerVisitState()
		const onClosePopup = () => {
			isPreviewVisitListVisible.value = false
		}
		return {
			formName,
			onClosePopup,
		}
	},
}
</script>

<style lang="scss" scoped></style>
