<template>
	<div>
		<div>
			<VPopup :closePopup="onClosePopup" style="width: 100%">
				<template #header>
					<div>
						{{ formName }}
					</div>
				</template>
				<template #body>
					<div style="margin-top: 30px">
						<AllVisitableCustomersModalFrameSubComponent />
					</div>
				</template>
				<template #footer>
					<div>
						{{ formName }}
					</div>
				</template>
			</VPopup>
		</div>
	</div>
</template>

<script>
import { ref } from '@vue/composition-api'
import VPopup from '@/_srcv2/pages/admin/edit-stock-prices/sub-components/VPopup.vue'
import AllVisitableCustomersModalFrameSubComponent from '@/_srcv2/pages/business-development/daily-visits/components/AllVisitableCustomersModalFrameSubComponent.vue'
import useCreateCustomerVisitState from '@/_srcv2/pages/business-development/customer-visits/scripts/useCreateCustomerVisitState.js'

export default {
	name: 'AllVisitableCustomersModalFrame',
	components: {
		VPopup,
		AllVisitableCustomersModalFrameSubComponent,
	},
	setup() {
		const formName = ref('Create Customer Visit of an Alarm')
		const { isAllVisitableCustomersModalFrameVisible } =
			useCreateCustomerVisitState()
		const onClosePopup = () => {
			isAllVisitableCustomersModalFrameVisible.value = false
		}
		return {
			onClosePopup,
			formName,
		}
	},
}
</script>

<style lang="scss" scoped></style>
