<!-- @format -->

<template>
	<div>
		<div v-if="isFrameVisible">
			<CreateCustomerVisitFrame />
		</div>
		<div>
			<div style="background-color: rgb(76, 92, 122)">
				<div class="table-header">{{ tableTitleByType }}</div>
			</div>
			<vue-good-table
				:columns="columns"
				:rows="rows"
				styleClass="vgt-table striped bordered"
				theme="black-rhino"
				max-height="700px"
				:fixed-header="true"
				@on-cell-click="onCellClick"
				:search-options="{
					enabled: true,
				}"
				:sort-options="sortOptions"
				:pagination-options="{
					enabled: true,
					mode: 'records',
					perPage: 10,
					position: 'bottom',
					perPageDropdown: [5, 10, 15, 20],
					dropdownAllowAll: true,
					setCurrentPage: 1,
					jumpFirstOrLast: true,
					firstLabel: 'First',
					lastLabel: 'Last',
					nextLabel: 'next',
					prevLabel: 'prev',
					rowsPerPageLabel: 'Rows per page',
					ofLabel: 'of',
					pageLabel: 'page', // for 'pages' mode
					allLabel: 'All',
				}"
			>
				<template slot="table-row" slot-scope="props">
					<span v-if="props.column.field === 'select'">
						<b-button class="is-small is-success">Visit</b-button>
					</span>
					<span v-if="props.column.field === 'alarm'">
						<b-button class="is-small is-danger">Alarm</b-button>
					</span>
					<span v-else>
						{{ props.formattedRow[props.column.field] }}
					</span>
				</template>
			</vue-good-table>
			<div
				style="
					background-color: rgb(76, 92, 122);
					padding-bottom: 30px;
					display: flex;
					justify-content: space-between;
				"
			></div>
		</div>
	</div>
</template>

<script>
import CreateCustomerVisitFrame from '@/_srcv2/pages/business-development/customer-visits/components/CreateCustomerVisitFrame.vue'
import { onMounted, reactive, ref } from '@vue/composition-api'
import { useQuery } from '@vue/apollo-composable'
import GetAllVisitableCustomersAddressListQuery from '@/_srcv2/pages/business-development/daily-visits/graphql/GetAllVisitableCustomersAddressListQuery.graphql'
import Store from '@/store'
import useRouteListState from '@/_srcv2/views/pages/admin-reports/customer-route-list/useRouteListState.js'
import useCreateCustomerVisitState from '@/_srcv2/pages/business-development/customer-visits/scripts/useCreateCustomerVisitState.js'

export default {
	name: 'AllVisitableCustomersModalFrameSubComponent',
	components: { CreateCustomerVisitFrame },
	setup() {
		const tableTitleByType = ref('All visitable customers')
		const { sortOptions, isModalVisible } = useRouteListState()
		const closeModal = () => (isModalVisible.value = false)
		const showModal = () => (isModalVisible.value = true)
		// todo -----------------------------------------------------------
		const {
			isFrameVisible,
			noteType,
			mutationType,
			customerID,
			addressID,
			visitNotes,
			visitAlarmNotes,
			customer,
			keyCreateCustomerVisitFrame,
		} = useCreateCustomerVisitState()
		// ** -----------------------------------------------------------------------------------
		const queryVariables = ref({
			company_id: Store.getters.getUserCurrentCompany,
			customer_is_active: true,
		})
		// ** -----------------------------------------------------------------------------------
		onMounted(() => {
			refetch()
			// ? -------------------
			sortOptions.initialSortBy = [
				{ field: 'dayOfWeek', type: 'asc' },
				{ field: 'routeId', type: 'asc' },
				{ field: 'postCode', type: 'asc' },
				{ field: 'line', type: 'asc' },
			]
		})
		const options = reactive({
			fetchPolicy: 'cache-first',
		})
		const { refetch, onResult } = useQuery(
			GetAllVisitableCustomersAddressListQuery,
			() => queryVariables.value,
			options,
		)
		onResult((result) => {
			fetchAddresses(result.data.addresses).then((data) => (rows.value = data))
		})
		const fetchAddresses = (data) => {
			return new Promise((resolve, reject) => {
				const addresses = data.map((item) => {
					return {
						customerId: item.customer.customer_id,
						customerTitle: item.customer.customer_title,
						customerNickname: item.customer.customer_nickname,
						customer: `${item.customer.customer_title} - ${item.customer.customer_nickname}`,
						line: `${item.line_1} ${item.line_2}`,
						city: item.city,
						postCode: item.post_code,
						routeId: item?._cities?.route_id_by_city || '',
						dayOfWeek: item?._cities?._adresses_route?.dayOfWeek || '',
						addressId: item.address_id,
						companyId: item.company_id,
						addressNickname: item.address_nickname,
						phone: item.address_phone || '',
						person: item.order_clerk || '',
						listGroup: item?.list_group || '',
						openHours: item?.open_hours || '',
					}
				})
				if (data !== null || undefined) {
					resolve(addresses)
				} else {
					const reason = new Error('Data could not be fetched from database')
					reject(reason)
				}
			})
		}
		const rows = ref([])
		const columns = [
			{
				label: 'Visit',
				field: 'select',
				width: '25px',
			},
			{
				label: 'Alarm',
				field: 'alarm',
				width: '25px',
			},
			{
				label: 'Day of week',
				field: 'dayOfWeek',
				width: '100px',
			},
			{
				label: 'Customer',
				field: 'customer',
				width: '200px',
			},
			{
				label: 'Street',
				field: 'line',
				width: '200px',
			},
			{
				label: 'City',
				field: 'city',
				width: '65px',
			},
			{
				label: 'Post Code',
				field: 'postCode',
				width: '85px',
			},
			{
				label: 'Route-Id',
				field: 'routeId',
				width: '85px',
			},
			{
				label: 'Phone',
				field: 'phone',
				width: '100px',
			},
			{
				label: 'Person',
				field: 'person',
				width: '100px',
			},
		]
		// todo ---------------------------------------------------------------------------------------------------------
		const onCellClick = (params) => {
			if (params.column.field === 'select') {
				console.log('visit params.row', params.row)
				noteType.value = 'visitNote'
				mutationType.value = 'add'
				customerID.value = params.row.customerId
				addressID.value = params.row.addressId
				visitNotes.value = ''
				customer.value = params.row.customer
				console.log('params.row', params.row)
				isFrameVisible.value = true
				keyCreateCustomerVisitFrame.value += 1
			} else if (params.column.field === 'alarm') {
				console.log('alarm params.row', params.row)
				noteType.value = 'alarmNote'
				mutationType.value = 'add'
				customerID.value = params.row.customerId
				addressID.value = params.row.addressId
				visitAlarmNotes.value = ''
				customer.value = params.row.customer
				console.log('params.row', params.row)
				isFrameVisible.value = true
				keyCreateCustomerVisitFrame.value += 1
			}
		}
		return {
			tableTitleByType,
			isModalVisible,
			isFrameVisible,
			showModal,
			closeModal,
			onCellClick,
			rows,
			columns,
			sortOptions,
			keyCreateCustomerVisitFrame,
		}
	},
}
</script>

<style scoped></style>
