var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('LayoutWide',[_c('sub-nav-bar',{attrs:{"page":"Marketing"}}),_c('div',[_c('HeroBox',{attrs:{"title":_vm.title,"subtitle":_vm.subtitle,"color":_vm.color}})],1),_c('section',[_c('b-field',{staticClass:"margin-top-20",attrs:{"label":"Select beginning date"}},[_c('b-datepicker',{attrs:{"show-week-number":_vm.showWeekNumber,"locale":_vm.locale,"placeholder":"Click to select...","icon":"calendar-today","trap-focus":""},model:{value:(_vm.selectedBeginningDate),callback:function ($$v) {_vm.selectedBeginningDate=$$v},expression:"selectedBeginningDate"}})],1),_c('b-field',{attrs:{"label":"Select ending date"}},[_c('b-datepicker',{attrs:{"show-week-number":_vm.showWeekNumber,"locale":_vm.locale,"placeholder":"Click to select...","icon":"calendar-today","trap-focus":""},model:{value:(_vm.selectedEndingDate),callback:function ($$v) {_vm.selectedEndingDate=$$v},expression:"selectedEndingDate"}})],1),_c('b-button',{staticClass:"is-info form-btn margin-top-20",staticStyle:{"width":"200px","margin-bottom":"30px","margin-left":"0px"},attrs:{"type":"submit"},on:{"click":_vm.fetchDates}},[_vm._v("Get Today's Results")])],1),_c('div',{staticStyle:{"background-color":"rgb(76, 92, 122)"}},[_c('div',{staticClass:"table-header"},[_vm._v(_vm._s(_vm.tableTitle))])]),_c('vue-good-table',{attrs:{"columns":_vm.columnsDailyAlarmList,"rows":_vm.rows,"styleClass":"vgt-table striped bordered","theme":"black-rhino","max-height":"700px","fixed-header":true,"search-options":{
			enabled: true,
		},"sort-options":_vm.sortOptions,"pagination-options":{
			enabled: true,
			mode: 'records',
			perPage: 10,
			position: 'bottom',
			perPageDropdown: [5, 10, 15, 20],
			dropdownAllowAll: true,
			setCurrentPage: 1,
			jumpFirstOrLast: true,
			firstLabel: 'First',
			lastLabel: 'Last',
			nextLabel: 'next',
			prevLabel: 'prev',
			rowsPerPageLabel: 'Rows per page',
			ofLabel: 'of',
			pageLabel: 'page', // for 'pages' mode
			allLabel: 'All',
		}},on:{"on-cell-click":_vm.onCellClick},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'select')?_c('span',[_c('b-button',{staticClass:"is-small is-success"},[_vm._v("Visit")])],1):(props.column.field === 'alarm')?_c('span',[_c('b-button',{staticClass:"is-small is-danger"},[_vm._v("Alarm")])],1):(props.column.field === 'edit')?_c('span',[_c('b-button',{staticClass:"is-small is-info"},[_vm._v("Edit")])],1):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])}),_c('div',{staticStyle:{"background-color":"rgb(76, 92, 122)","padding-bottom":"30px","display":"flex","justify-content":"space-between"}},[_c('div',[_c('b-button',{staticClass:"margin-top-30 margin-left-15 width-180",staticStyle:{"background-color":"rgb(72, 199, 142)","color":"white"},on:{"click":function($event){return _vm.refetch()}}},[_vm._v(" Refresh the Data ")]),_c('b-button',{staticClass:"margin-top-30 margin-left-15 width-180",staticStyle:{"background-color":"rgb(182, 90, 92)","color":"white"},on:{"click":_vm.addNewAlarm}},[_vm._v(" Add New Alarm ")]),_c('b-button',{staticClass:"margin-top-30 margin-left-30 width-180",staticStyle:{"background-color":"rgb(62, 142, 209)","color":"white"},on:{"click":_vm.previewAlarmList}},[_vm._v(" Preview the Alarm List ")])],1),_c('div',[_c('download-csv',{attrs:{"data":_vm.mappedRows,"name":_vm.fileName}},[_c('div',{staticClass:"margin-top-30 width-180",staticStyle:{"margin-right":"15px"}},[_c('b-button',{attrs:{"type":"is-danger","outlined":""}},[_vm._v(" Download Alarm List ")])],1)])],1)]),(_vm.isAllVisitableCustomersModalFrameVisible)?_c('div',[_c('AllVisitableCustomersModalFrame')],1):_vm._e(),(_vm.isPreviewVisitListVisible)?_c('div',[_c('PreviewVisitList',{attrs:{"data":_vm.previewData,"columns":_vm.previewColumns}})],1):_vm._e(),(_vm.isFrameVisible)?_c('div',[_c('CreateCustomerVisitFrame')],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }