var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isFrameVisible)?_c('div',[_c('CreateCustomerVisitFrame')],1):_vm._e(),_c('div',[_c('div',{staticStyle:{"background-color":"rgb(76, 92, 122)"}},[_c('div',{staticClass:"table-header"},[_vm._v(_vm._s(_vm.tableTitleByType))])]),_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.rows,"styleClass":"vgt-table striped bordered","theme":"black-rhino","max-height":"700px","fixed-header":true,"search-options":{
				enabled: true,
			},"sort-options":_vm.sortOptions,"pagination-options":{
				enabled: true,
				mode: 'records',
				perPage: 10,
				position: 'bottom',
				perPageDropdown: [5, 10, 15, 20],
				dropdownAllowAll: true,
				setCurrentPage: 1,
				jumpFirstOrLast: true,
				firstLabel: 'First',
				lastLabel: 'Last',
				nextLabel: 'next',
				prevLabel: 'prev',
				rowsPerPageLabel: 'Rows per page',
				ofLabel: 'of',
				pageLabel: 'page', // for 'pages' mode
				allLabel: 'All',
			}},on:{"on-cell-click":_vm.onCellClick},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'select')?_c('span',[_c('b-button',{staticClass:"is-small is-success"},[_vm._v("Visit")])],1):_vm._e(),(props.column.field === 'alarm')?_c('span',[_c('b-button',{staticClass:"is-small is-danger"},[_vm._v("Alarm")])],1):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])}),_c('div',{staticStyle:{"background-color":"rgb(76, 92, 122)","padding-bottom":"30px","display":"flex","justify-content":"space-between"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }